import websocket from '@/common/websocket'
import {
  baseUrl
} from '../common/request/env'
//登录接口
export function login(data) {
  let { username, password } = data
  return websocket.send({
    "cmd": 'userLogin',
    "user": username,
    "password": password
  })
}

//二维码Token
export function refQRCode() {
  return websocket.send({
    "cmd": 'createLoginToken',
  })

}