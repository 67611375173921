<template>
	<div id="register">
		<div class="register">
			<div class="title"><i class="el-icon-s-custom"></i>用户注册</div>
			<div class="content">
				<el-form ref="form" :rules="rules" :model="form" label-width="80px">
					<el-form-item label="账号" prop="username">
						<el-input v-model="form.username" placeholder="请输入账号"></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="tel">
						<el-input v-model="form.tel" placeholder="请输入手机号（选填）"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirm">
						<el-input v-model="form.confirm" type="password" placeholder="请再次确认密码"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" style="width: 100%;" :loading="isLoading" @click="registerReady">
							{{isLoading?'正在注册，请稍后':'立即注册'}}
						</el-button>
					</el-form-item>
					<el-form-item>
						<el-link type="primary" :underline="false" style="width: 100%; margin-top: -40px;" @click="back"
							:disabled="isLoading">
							< 返回登录</el-link>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			// 电话号码验证
			let validateTel = (rule, value, callback) => {
				const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
				if (!value) {
					return callback()
				}
				setTimeout(() => {
					if (!Number.isInteger(+value)) {
						callback(new Error('请输入数字值'))
					} else {
						if (phoneReg.test(value)) {
							callback()
						} else {
							callback(new Error('电话号码格式不正确'))
						}
					}
				}, 100)
			}
			// 密码验证
			let validatePassword = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.form.password !== '') {
						this.$refs.form.validateField('confirm');
					}
					callback();
				}
			};
			// 确认密码验证
			let validateConfirm = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次确认密码'));
				} else if (value !== this.form.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				isLoading: false,
				form: {
					tel: '',
					username: '',
					password: '',
					confirm: ''
				},
				rules: {
					tel: [{
						validator: validateTel,
						trigger: 'blur'
					}],
					username: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						validator: validatePassword,
						trigger: 'blur'
					}],
					confirm: [{
						required: true,
						validator: validateConfirm,
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			registerReady() {
				this.$refs['form'].validate((valid) => {
					if (!valid) {
						return;
					}
					let json = {
						"cmd": "registerUser",
						"user": this.form.username,
						"password": this.form.password,
						"tel": this.form.tel
					}
					console.log(json);
					this.ws.send(json);
					this.isLoading = true;
				});
			},
			// 返回登录
			back() {
				this.$emit('back');
			}
		},
		mounted() {
			this.ws.addCallback('registerUserAck', (res) => {
				if (!this.common.cGetResult(res)) {
					this.common.cToast(this, "注册失败");
					this.isLoading = false;
					return;
				}
				this.common.cToast(this, "注册成功，请登录", 'success');
				this.isLoading = false;
				this.form = {
					tel: '',
					username: '',
					password: '',
					confirm: ''
				}
				this.$emit('back');
			})
		}
	}
</script>

<style lang="scss">
	#register {
		.register {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 500px;
			height: 450px;
			box-shadow: 0 2px 10px #CCCCCC;
			border-radius: 8px;
			background-color: rgba($color: $color_white, $alpha: 0.9);

			.title {
				padding: 4% 0;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				background-color: rgba($color: #44A4FA, $alpha: 0.1);
				color: #44A4FA;
				text-shadow: 1px 1px 0 $color_white;
				display: flex;
				justify-content: center;
				align-items: center;

				i {
					font-size: 26px;
					margin-right: 6px;
				}
			}

			.content {
				padding: 6% 18% 0 15%;
			}
		}
	}
</style>
